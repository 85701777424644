import {HttpResponse} from './HttpResponse';
import {HttpRequest} from './HttpRequest';
import {Uri} from "./Uri";

export interface HttpClient {
  handle(req: HttpRequest): Promise<HttpResponse>;
}

export class HttpFetchClient implements HttpClient {
  handle(request: HttpRequest): Promise<HttpResponse> {
    switch (request.method) {
      case 'GET':
        return get(request);
      case 'POST':
        return wire(request);
      case 'PUT':
        return wire(request);
      case 'PATCH':
        return wire(request);
      case 'DELETE':
        return wire(request);
      case 'HEAD':
        return wire(request);
      case 'OPTIONS':
        return wire(request);
      case 'TRACE':
        return wire(request);

      default:
        return get(request)
    }
  }
}

function get(request: HttpRequest): Promise<HttpResponse> {
  return fetch(request.uri.toString(), {
    headers: new Headers(request.headers),
    method: request.method,
    mode: 'cors',
    redirect: 'follow',
    referrer: 'no-referrer',
  }).then(res => {
    return res.text().then(text => {
      return new HttpResponse(res.status, request.uri, text);
    })
  });
}

function wire(request: HttpRequest): Promise<HttpResponse> {
  return fetch(request.uri.toString(), {
    body: request.body.toString(), // must match 'Content-Type' header
    headers: new Headers(request.headers),
    method: request.method,
    mode: 'cors',
    redirect: 'follow',
    referrer: 'no-referrer',
  }).then(res => {
    return res.text().then(text => {
      return new HttpResponse(res.status, request.uri, text);
    })
  });
}
