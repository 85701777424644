import React from "react";
import {Header} from "antd/lib/layout/layout";
import {Link} from "wouter";
import {ProfileMenu} from "../authentication/ProfileMenu";

export function DotHeader() {
    return <Header>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div className="left-group">
                <Link to="/">
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}>
                        <img style={{padding: '5px'}} src="/icon64.png" width={36} alt="dream of tea icon"/>
                        <span className="logo-main">dream</span><span className="logo-highlight">of</span><span
                        className="logo-main">tea</span>
                    </div>
                </Link>
            </div>
            <div className="middle-group">

            </div>
            <div className="right-group">
                <ProfileMenu />
            </div>
        </div>
    </Header>
}