import {Dispatch, SetStateAction, useState} from "react";
import {State} from "./State";

export function useQueryParam(key: string, history: History = window.history): State<string> {
    const initialValue = new URLSearchParams(window.location.search).get(key) || "";
    const [value, setValue] = useState<string>(initialValue);

    const setter: Dispatch<SetStateAction<string>> = (update: SetStateAction<string>) => {
        const location = window.location;
        const baseUrl = [location.protocol, '//', location.host, location.pathname].join('')
        const searchParams = new URLSearchParams(location.search);
        const newValue = update.toString();
        if (newValue.length > 0) {
            searchParams.set(key, newValue)
        } else {
            searchParams.delete(key)
        }
        const queryString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ''
        history.replaceState('data', newValue, baseUrl + `${queryString}`);
        setValue(update)
    }

    return [value, setter]
}