import {HttpMessage} from './HttpMessage';
import {Body} from './Body';
import {Uri} from './Uri';

export class HttpResponse implements HttpMessage {
  uri: Uri | string;
  headers: Record<string, string> = {};
  body: Body;
  status: number;

  constructor(status: number, uri: Uri | string, body: Body | string = new Body("")) {
    this.uri = uri
    this.body = typeof body === "string"
      ? new Body(body)
      : body;
    this.status = status;
  }
  header(name: string): string {
    return this.headers[name.toLowerCase()];
  }

  withHeader(name: string, value: string): HttpResponse {
    const response = HttpResponse.clone(this);
    const lowercaseName = name.toLowerCase();
    if (response.headers[lowercaseName] == null) {
      response.headers[lowercaseName] = value;
    } else if (typeof response.headers[lowercaseName] === "string") {
      response.headers[lowercaseName] = [response.headers[lowercaseName], value];
    } else {
      response.headers[lowercaseName].push(value);
    }
    return response;
  }

  withHeaders(headers: object): HttpResponse {
    const response = HttpResponse.clone(this);
    response.headers = headers;
    return response;
  }

  replaceAllHeaders(headers: object): HttpResponse {
    const response = HttpResponse.clone(this);
    response.headers = headers;
    return response;
  }

  replaceHeader(name: string, value: string): HttpResponse {
    const response = HttpResponse.clone(this);
    response.headers[name] = value;
    return response;
  }

  removeHeader(name: string): HttpResponse {
    const response = HttpResponse.clone(this);
    delete response.headers[name];
    return response;
  }

  withBody(body: Body | string): HttpResponse {
    const response = HttpResponse.clone(this);
    typeof body == "string"
      ? response.body.bytes = body
      : response.body = body;
    return response;
  }

  bodyString(): string {
    return this.body.bodyString();
  }

  private static clone(a: any) {
    return Object.assign(Object.create(a), a)
  }

}
