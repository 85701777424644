import {Col, Row} from "antd";
import React, {ChangeEvent, useState} from "react";
import {ImageReq} from "../../api/api";
import Search from "antd/lib/input/Search";
import {State} from "../hooks/State";

type PromptProps = {
    searchState: State<string>
    generateImage: (req: ImageReq) => void
};

export function Prompt({generateImage, searchState: [search, setSearch]}: PromptProps) {

    const promptEntered = async (prompt: string) => {
        await generateImage({prompt})
    }

    return <Row style={{padding: '10px'}}>
        <Col xs={24} md={24} lg={24}>
            <Search placeholder="create an image of..."
                    enterButton="create"
                    type="primary"
                    size="large"
                    value={search}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                    onSearch={promptEntered} />
        </Col>
    </Row>
}
