import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {Auth0Provider} from "@auth0/auth0-react";
import {ConfigProvider, theme} from "antd";
import ReactGA from "react-ga4";


ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Auth0Provider
            domain="dev-w72o1yntkcfc5dwi.us.auth0.com"
            clientId="j98zsQEJ0O9HorNn8J0ZUVY1g37T82ZG"
            cacheLocation="localstorage"
            authorizationParams={{
                redirect_uri: window.location.origin
            }}
        >
            <ConfigProvider theme={{
                algorithm: theme.darkAlgorithm,
                token: {
                    colorPrimary: '#daa520',
                    colorPrimaryBg: '#daa520',
                    colorPrimaryHover: '#daa520'
                }
            }}>
                <App/>
            </ConfigProvider>
        </Auth0Provider>
    </React.StrictMode>
);

const TRACKING_ID = "G-ZBKFS1DNGP";
ReactGA.initialize(TRACKING_ID);
