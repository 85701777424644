import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {Button} from "antd";

export function LogoutPage() {
    const { logout } = useAuth0()

    useEffect(() => {
        logout()
    })

    return <div>Logging out...</div>
}