import {
    RedoOutlined,
    DeleteOutlined,
    SearchOutlined,
    MenuOutlined,
    HeartOutlined,
    HeartFilled,
    PictureOutlined
} from "@ant-design/icons"

export const Icons = {
    refresh: RedoOutlined,
    delete: DeleteOutlined,
    search: SearchOutlined,
    menu: MenuOutlined,
    favourite: HeartFilled,
    notFavourite: HeartOutlined,
    images: PictureOutlined
}