import {Card, Spin} from "antd";
import Meta from "antd/es/card/Meta";
import {Icons} from "../../style/Icons";
import React from "react";
import {Image} from "../../protocol/images/Image";
import {Link} from "wouter";

type ImageCardProps = {
    image: Image,
    replay: (prompt: string) => void,
    deleteImage: (imageId: string, permanently: boolean) => void,
    toggleFavourite: (imageId: string) => void,
};

function resolveActions(image: Image, toggleFavourite: (imageId: string) => void, replay: (prompt: string) => void, deleteImage: (imageId: string, permanently: boolean) => void) {
    const permanentlyDeleteAction = <div onClick={() => deleteImage(image.imageId, true)}>
        <Icons.delete key="delete"/> Delete Forever
    </div>;
    const deleteAction = <div onClick={() => deleteImage(image.imageId, false)}>
        <Icons.delete key="delete"/> Delete
    </div>;
    const replayAction = <div onClick={() => replay(image.prompt)}><Icons.refresh key="refresh"/> Replay</div>
    const favouriteIcon = image.favourite ? <Icons.favourite key="favourite"/> : <Icons.notFavourite key="favourite"/>;
    const favouriteAction =<div onClick={() => toggleFavourite(image.imageId)}><>{favouriteIcon} Like</></div>
    if (image.deleted) {
        return [permanentlyDeleteAction]
    }
    if (image.status !== 'completed') {
        return [replayAction]
    }
    return [
        favouriteAction,
        replayAction,
        deleteAction
    ]
}

export function ImageCard({image, toggleFavourite, replay, deleteImage}: ImageCardProps) {
    const actions = resolveActions(image, toggleFavourite, replay, deleteImage)

    return <Card cover={image.status === 'completed'
        ? <Link to={`images/${image.imageId}`}><img alt={image.prompt} src={image.imageUrl}/></Link>
        : <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            textAlign: 'center',
            height: '100%',
            minHeight: '150px',
            width: '100%'
        }}>
            <Spin style={{margin: 'auto'}}/>
        </div>}
                 actions={actions}
    >
        <Meta title={image.prompt === "" ? <i>blank</i> : image.prompt} description={image.status}/>
    </Card>

}