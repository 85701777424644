import React, {useState} from 'react';
import {Layout, Spin} from 'antd';
import {useAuth0} from "@auth0/auth0-react";
import {DotHeader} from "./header/DotHeader";
import {Route} from "wouter";
import {ImagesPage} from "./images/ImagesPage";
import {ImagePage} from "./images/ImagePage";
import {Box} from "./components/Box";
import {LogoutPage} from "./authentication/LogoutPage";

const {Content} = Layout;


function AuthenticatedApp() {
    const [accessToken, setAccessToken] = useState<string>("");
    const {isAuthenticated, getAccessTokenSilently} = useAuth0();
    if (!isAuthenticated) {
        return null
    }


    getAccessTokenSilently().then(accessToken => setAccessToken(accessToken.toString()))

    if (!accessToken) {
        return <Box><Spin/></Box>
    }

    return (
        <Layout>
            <DotHeader/>

            <Content style={{padding: '5px'}}>
                <Route path="/" component={() => <ImagesPage accessToken={accessToken}/>}/>
                <Route path="/favourites" component={() => <ImagesPage accessToken={accessToken} favourites={true}/>}/>
                <Route path="/deleted" component={() => <ImagesPage accessToken={accessToken} deleted={true}/>}/>
                <Route path="/logout" component={() => <LogoutPage/>}/>
                <Route path="/images/:imageId"
                       component={({params}: any) => <ImagePage accessToken={accessToken} imageId={params.imageId}/>}/>
            </Content>
        </Layout>
    );
}

export default AuthenticatedApp;
