import {Prompt} from "./Prompt";
import {MyImages} from "./MyImages";
import React, {useState} from "react";
import {HttpApi, ImageReq} from "../../api/api";
import {AuthHttpClient} from "../../http/AuthHttpClient";
import {Image} from "../../protocol/images/Image";
import {SecuredPage} from "../authentication/SecuredPage";
import {State} from "../hooks/State";
import {FilterBar} from "./FilterBar";
import {useQueryParam} from "../hooks/useQueryParam";

type ImagesPageProps = {
    favourites?: boolean
    deleted?: boolean
} & SecuredPage

export function ImagesPage({accessToken, favourites = false, deleted = false}: ImagesPageProps) {
    const httpApi = new HttpApi(new AuthHttpClient(accessToken))
    const filterState: State<string> = useQueryParam("filter")
    const searchState: State<string> = useState<string>("")

    const [_, setSearch] = searchState
    const [filter] = filterState;
    const [images, setImages] = useState([] as Image[]);

    const loadImages = async () => {
        const images = await httpApi.myImages(filter, favourites, deleted);
        setImages(images)
    }

    const generateImage = async (req: ImageReq) => {
        await httpApi.generateImage(req);
        await loadImages()
    }

    const replay = async (req: ImageReq) => {
        setSearch(req.prompt)
        await generateImage(req)
    }

    const deleteImage = async (imageId: string, permanently: boolean) => {
        if (permanently) {
            await httpApi.deleteImagePermanently(imageId)
        } else {
            await httpApi.deleteImage(imageId)
        }
        setImages(images.filter(i => i.imageId !== imageId))
    }

    const toggleFavourite = async (imageId: string) => {
        await httpApi.toggleFavourite(imageId);
        const imageIndex = images.findIndex(i => i.imageId === imageId);
        setImages([
            ...images.slice(0, imageIndex),
            {
                ...images[imageIndex],
                favourite: !images[imageIndex].favourite,
            },
            ...images.slice(imageIndex + 1, images.length - imageIndex),
        ])
    }

    return <>
        {!favourites && !deleted && <Prompt generateImage={generateImage} searchState={searchState}/>}
        <FilterBar filterState={filterState}/>
        <MyImages deleteImage={deleteImage}
                  toggleFavourite={toggleFavourite}
                  loadImages={loadImages}
                  replayImage={replay}
                  images={images}/>
    </>
}