import React, {useEffect, useState} from "react";
import {SecuredPage} from "../authentication/SecuredPage";
import {HttpApi} from "../../api/api";
import {AuthHttpClient} from "../../http/AuthHttpClient";
import {Image} from "../../protocol/images/Image";
import {Spin} from "antd";
import {Box} from "../components/Box";

export type ImagePageProps = SecuredPage & {
    imageId: string
}

export function ImagePage({accessToken, imageId}: ImagePageProps) {
    const httpApi = new HttpApi(new AuthHttpClient(accessToken))
    const [image, setImage] = useState<Image>();


    useEffect(() => {
        httpApi.imageForId(imageId).then(setImage);
    }, [imageId])


    console.log("Image: ", image)
    const content = image
        ? <div>
            <img alt={image.prompt} src={image.imageUrl}/>
            <h3>{image.prompt}</h3>
        </div>
        : <Spin style={{margin: 'auto'}}/>

    return <>
        <Box width={512} height={512}>
            {content}
        </Box>
    </>
}