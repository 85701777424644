import {Image} from "../protocol/images/Image";
import {HttpClient, HttpFetchClient} from "../http/HttpClient";
import {ReqOf} from "../http/HttpRequest";
import {Method} from "../http/Method";
import {Uri} from "../http/Uri";

export interface ImageReq {
    prompt: string
}

export interface Api {
    generateImage(imageReq: ImageReq): Promise<Image | undefined>

    myImages(filter: string, favourites?: boolean, deleted?: boolean): Promise<Image[]>

    publicImages(): Promise<Image[]>

    deleteImage(imageId: string): Promise<any>

    toggleFavourite(imageId: string): Promise<any>
}

export class HttpApi implements Api {
    private httpClient: HttpClient;
    private unauthenticatedHttpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.unauthenticatedHttpClient = new HttpFetchClient()
        this.httpClient = httpClient
    }


    async generateImage(imageReq: ImageReq): Promise<Image | undefined> {
        const res = await this.httpClient.handle(ReqOf(Method.POST, "/api/v1/images", JSON.stringify(imageReq), {'Content-Type': "application/json"}))
        return JSON.parse(res.bodyString())
    }

    async myImages(filter: string, favourites: boolean, deleted: boolean): Promise<Image[]> {
        const uri = Uri.of("/api/v1/images")
            .withQuery("filter", filter)
            .withQuery("favourites", favourites)
            .withQuery("deleted", deleted);
        const req = ReqOf(Method.GET, uri.asUriString());
        const res = await this.httpClient.handle(req)
        return JSON.parse(res.bodyString())
    }

    async publicImages(): Promise<Image[]> {
        const res = await this.unauthenticatedHttpClient.handle(ReqOf(Method.GET, "/api/v1/images/public"))
        return JSON.parse(res.bodyString())
    }

    async deleteImage(imageId: string) {
        const res = await this.httpClient.handle(ReqOf(Method.DELETE, `/api/v1/images/${imageId}`))
        return JSON.parse(res.bodyString())
    }

    async deleteImagePermanently(imageId: string) {
        const res = await this.httpClient.handle(ReqOf(Method.DELETE, `/api/v1/images/permanently/${imageId}`))
        return JSON.parse(res.bodyString())
    }

    async imageForId(imageId: string) {
        const res = await this.httpClient.handle(ReqOf(Method.GET, `/api/v1/image/${imageId}`))
        return JSON.parse(res.bodyString())
    }

    async toggleFavourite(imageId: string) {
        const res = await this.httpClient.handle(ReqOf(Method.POST, `/api/v1/images/favourite/${imageId}`))
        return JSON.parse(res.bodyString())
    }
}
