import React from "react";

export function DreamOfTea(props: any) {

    return (<span style={{fontSize: props.size, textAlign: 'center', width: '100%'}}>
        <span className="logo-main">dream</span>
        <span className="logo-highlight">of</span>
        <span className="logo-main">tea</span>
    </span>)

}