import {HttpClient, HttpFetchClient} from "./HttpClient";
import {HttpRequest} from "./HttpRequest";
import {HttpResponse} from "./HttpResponse";

export class AuthHttpClient implements HttpClient {
    private httpClient: HttpClient;
    private accessToken: string;

    constructor(accessToken: string,
                httpClient: HttpClient = new HttpFetchClient()) {
        this.accessToken = accessToken;
        this.httpClient = httpClient;
    }

    async handle(request: HttpRequest): Promise<HttpResponse> {
        return await this.httpClient.handle(request
            .withAuthorization(this.accessToken))
    }
}