import {Col, Input, Pagination, Row} from "antd";

import "./FilterBar.css"
import {Icons} from "../../style/Icons";
import React from "react";
import {State} from "../hooks/State";

type FilterBarProps = { filterState: State<string> };

export function FilterBar({filterState: [filter, setFilter]}: FilterBarProps) {
    return <Row style={{padding: '0px 10px'}} gutter={0} justify="space-between">
        <Col>
            <Input placeholder="filter"
                   suffix={<Icons.search/>}
                   value={filter}
                   onChange={e => setFilter(e.target.value)}/>
        </Col>
        <Col>
            {/*<Pagination size="small" defaultCurrent={1} total={50}/>*/}
        </Col>
    </Row>
}