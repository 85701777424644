import {Button, Dropdown, MenuProps} from "antd";
import {LogoutPage} from "./LogoutPage";
import React from "react";
import {Icons} from "../../style/Icons";
import {Link} from "wouter";
import {useAuth0} from "@auth0/auth0-react";

export function ProfileMenu() {
    const {logout} = useAuth0()

    const items: MenuProps['items'] = [
        {
            key: '1',
            icon: <Icons.images/>,
            label: (<Link to="/">All images</Link>),
        },
        {
            key: '2',
            icon: <Icons.favourite/>,
            label: (<Link to="/favourites">Favourites</Link>),
        },
        {
            key: '3',
            icon: <Icons.delete/>,
            label: (<Link to="/deleted">Deleted</Link>),
        },
        {type: 'divider'},
        {
            key: '4',
            label: (<Link to="/logout">Logout</Link>),
        },
    ];

    return <div>
        <Dropdown menu={{items}} placement="bottomRight">
            <Button><Icons.menu/></Button>
        </Dropdown>
    </div>
}