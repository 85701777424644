import React, {useEffect, useState} from "react";
import {Col, Row, Spin} from "antd";
import {useInterval} from "../hooks/useInterval";
import {Image} from "../../protocol/images/Image";
import {ImageCard} from "./ImageCard";
import {ImageReq} from "../../api/api";

type MyImagesProps = {
    images: Image[],
    replayImage: (req: ImageReq) => void,
    deleteImage: (imageId: string, permanently: boolean) => void,
    toggleFavourite: (imageId: string) => void,
    loadImages: () => void
};

export function MyImages({images, loadImages, toggleFavourite, replayImage, deleteImage}: MyImagesProps) {

    const [delay, setDelay] = useState(500)

    useEffect(() => {
        loadImages()
    }, [true])

    useInterval(async () => {
        loadImages()
        setDelay(5000)
    }, delay)

    const replay = async (prompt: string) => {
        await replayImage({prompt})
    }

    if (images.length === 0) {
        return <Row gutter={8}>
            <Col span={24}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyItems: 'center',
                    textAlign: 'center',
                    height: '100px',
                    width: '100%'
                }}>
                    <Spin style={{margin: 'auto'}}/>
                </div>
            </Col>
        </Row>
    }

    const cards = images.map(image => <Col xs={24} md={8} lg={6} style={{paddingTop: '10px'}} key={image.imageId}>
        <ImageCard image={image} replay={replay} deleteImage={deleteImage} toggleFavourite={toggleFavourite}/>
    </Col>);

    return <Row gutter={8}>
        {cards}
    </Row>
}
