
const pathParamMatchingRegex: RegExp = new RegExp(/\{(\w+)\}/g);
const pathParamCaptureTemplate: string = "([\\w\\s]+)";

export interface NodeURI {
  protocol: string,
  auth: string,
  host: string, //have to change this to change hostname or port
  port: string,
  hostname: string,
  query: string,
  pathname: string,
  path: string, //pathname with query
}

export class Uri {
  nativeUri: URL;
  matches: object = {};

  constructor(template: string, baseUrl: string = window.location.href) {
    this.nativeUri = new URL(template, baseUrl.toString());
  }

  static of(uri: string, baseUrl: string = window.location.href): Uri {
    return new Uri(uri, baseUrl)
  }

  asUriString(): string {
    return this.nativeUri.toString();
  }

  protocol(): string {
    return this.nativeUri.protocol.replace(/\:/g, "");
  }

  withProtocol(protocol: string): Uri {
    const uri = Uri.clone(this);
    uri.nativeUri.protocol = protocol;
    return Uri.of(uri.asUriString());
  }

  queryString(): string {
    return this.nativeUri.search;
  }

  withQuery(name: string, value?: string | boolean): Uri {
    if(!value) {
      return this
    }

    if (this.queryString() && this.queryString().length > 0) {
      return Uri.of(`${this.asUriString()}&${name}=${value}`);
    } else {
      return Uri.of(`${this.asUriString()}?${name}=${value}`);
    }
  }

  path(): string {
    return this.nativeUri.pathname;
  }

  withPath(path: string): Uri {
    const uri = Uri.clone(this);
    uri.nativeUri.pathname = path;
    return Uri.of(uri.asUriString());
  }

  hostname(): string {
    return this.nativeUri.hostname;
  }

  withHostname(hostname: string): Uri {
    const uri = Uri.clone(this);
    uri.nativeUri.host = `${hostname}:${uri.nativeUri.port}`;
    return Uri.of(uri.asUriString());
  }

  port(): string {
    return this.nativeUri.port;
  }

  withPort(port: number): Uri {
    const uri = Uri.clone(this);
    uri.nativeUri.host = `${uri.nativeUri.hostname}:${port}`;
    return Uri.of(uri.asUriString());
  }

  auth(): string {
    return `${this.nativeUri.username}:${this.nativeUri.password}`;
  }

  withAuth(username: string, password: string): Uri {
    const uri = Uri.clone(this);
    uri.nativeUri.auth = `${username}:${password}`;
    return Uri.of(uri.asUriString());
  }

  exactMatch(matchingOnPath: string): boolean {
    return new RegExp(`^${matchingOnPath}$`).exec(this.path()) != null;
  }

  templateMatch(matchingOnPath: string): boolean {
    return Uri.uriTemplateToPathParamCapturingRegex(this.path()).exec(matchingOnPath) != null;
  }

  private static uriTemplateToPathParamCapturingRegex(template: string): RegExp {
    return new RegExp(template.replace(
      pathParamMatchingRegex,
      pathParamCaptureTemplate)
    );
  }

  private static clone(a: any) {
    return Object.assign(Object.create(a), a);
  }
}