import {useAuth0} from "@auth0/auth0-react";
import React, {useLayoutEffect, useState} from "react";
import {Image} from "../protocol/images/Image";
import {HttpApi} from "../api/api";
import {Button, Col, Divider, Row} from "antd";
import {DreamOfTea} from "../style/DreamOfTea";
import "./HomePage.css"
import {HttpFetchClient} from "../http/HttpClient";

export function HomePage() {
    const {loginWithRedirect} = useAuth0();
    const [loaded, setLoaded] = useState(false);
    const [images, setImages] = useState([] as Image[]);
    useLayoutEffect(() => {
        const httpApi = new HttpApi(new HttpFetchClient())
        httpApi.publicImages()
            .then(images => {
                setImages(images)
                setLoaded(true)
            })
    }, [true])

    if (!loaded) {
        return <div>Loading...</div>
    }

    const columns = images.map(p => {
        return <Col key={p.imageId} xs={8} md={6} lg={4}><div className="photo" style={{
            backgroundImage: `url(${p.imageUrl})`
        }}/></Col>
    })


    return <div className="home-page">

        <div className="home-background">
            <Row className="home-overflowing">
                {columns}
            </Row>
        </div>
        <div className="home-panel">
            <Row>
                <img className="icon" src="icon64.png" alt="dreamoftea logo"/>
            </Row>
            <Row>
                <DreamOfTea size="larger"/>
            </Row>
            <Row>
                <p className="description">amazing AI generated images of tea and other things</p>
            </Row>
            <Divider/>
            <Row>
                <div className="buttons">
                    <Button onClick={() => loginWithRedirect()}>Log In / Sign up for Beta</Button>
                </div>
            </Row>
        </div>

    </div>
}