import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import AuthenticatedApp from "./application/AuthenticatedApp";
import {HomePage} from "./home/HomePage";

function App() {
    const {
        isAuthenticated,
        isLoading,
        // user
    } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    const content = isAuthenticated? <AuthenticatedApp/> : <HomePage/>

    return (
        content
    );
}

export default App;
