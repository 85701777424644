import {PropsWithChildren} from "react";

export type BoxProps = {
    width?: number | string
    height?: number | string
}

export function Box({children, width = '100%', height = '100%'}: PropsWithChildren<BoxProps>) {
    return <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        textAlign: 'center',
        height: '100%',
        width: '100%'
    }}>
        <div style={{
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            textAlign: 'center',
            width,
            height
        }}>
            {children}
        </div>
    </div>
}