import {Uri} from './Uri';
import {Body} from './Body';

export class HttpRequest {
    method: string;
    uri: Uri | string;
    body: Body | string;
    headers?: Record<string, string>;

    constructor(method: string,
                uri: Uri | string,
                body: Body | string = "",
                headers: Record<string, string> | undefined = undefined) {
        this.method = method;
        this.uri = uri;
        this.body = body;
        this.headers = headers;
    }

    withAuthorization(bearerToken: string) {
        return this.withHeader("Authorization", `Bearer ${bearerToken}`)
    }

    withHeader(key: string, value?: string) {
        return ReqOf(
            this.method,
            this.uri,
            this.body,
            {...this.headers, ...({[key]: value || ''})}
        )
    }
}

export function ReqOf(method: string,
                      uri: Uri | string,
                      body: Body | string = new Body(''),
                      headers: Record<string, string> | undefined = undefined): HttpRequest {
    return new HttpRequest(method, uri, body, headers);
}
